//frontend/solyo/components/Functions/Header/Notification_Center.tsx
'use client'

import { useState } from 'react'
import { Bell, ListChecks, ChevronRight } from 'lucide-react'
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"
import { Badge } from "@/components/ui/badge"

type Notification = {
  id: number
  title: string
  description: string
  requiresAction: boolean
  read: boolean
}

export default function NotificationCenter() {
  const [notifications, setNotifications] = useState<Notification[]>([
    { id: 1, title: 'New Feature', description: 'Check out our latest update!', requiresAction: false, read: false },
    { id: 2, title: 'Subscription Expiring', description: 'Your plan will end soon. Renew now!', requiresAction: true, read: false },
    { id: 3, title: 'Welcome', description: 'Thanks for joining our app!', requiresAction: false, read: false },
    { id: 4, title: 'Complete Profile', description: 'Please finish setting up your profile.', requiresAction: true, read: false },
  ])

  const handleNotificationClick = (id: number) => {
    const notification = notifications.find(n => n.id === id)
    if (notification) {
      if (notification.requiresAction) {
        console.log(`Action required for notification: ${notification.title}`)
        // Here you would typically navigate to a page or open a modal
        // For example: router.push(`/notifications/${id}`)
      } else {
        setNotifications(notifications.map(notif => 
          notif.id === id ? { ...notif, read: true } : notif
        ))
      }
    }
  }

  const completeNotificationAction = (id: number) => {
    setNotifications(notifications.map(notif => 
      notif.id === id ? { ...notif, read: true } : notif
    ))
  }

  const unreadNotifications = notifications.filter(n => !n.read)
  const unreadCount = unreadNotifications.length

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="relative">
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <Badge className="absolute -top-1 -right-1 px-1.5 py-0.5 text-xs bg-blue-600 text-white border-0">
              {unreadCount}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-64 p-0">
        <div className="flex items-center justify-between px-2 py-1.5 bg-gray-800 text-white">
          <h3 className="font-semibold text-sm">Notification Center</h3>
          <Button variant="ghost" size="sm" asChild className="h-6 px-2">
            <a href="/notifications" className="text-xs flex items-center text-white hover:text-gray-200">
              View All
              <ChevronRight className="h-3 w-3 ml-1" />
            </a>
          </Button>
        </div>
        <DropdownMenuSeparator />
        {unreadNotifications.map((notif) => (
          <DropdownMenuItem 
            key={notif.id} 
            onClick={() => handleNotificationClick(notif.id)}
            className="flex items-start justify-between p-2"
          >
            <div className="flex flex-col">
              <span className="font-semibold">{notif.title}</span>
              <span className="text-sm text-muted-foreground">
                {notif.description}
              </span>
            </div>
            {notif.requiresAction && (
              <Button 
                size="sm" 
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation()
                  completeNotificationAction(notif.id)
                }}
                className="ml-2"
              >
                <ListChecks className="h-4 w-4" />
                <span className="sr-only">Action required</span>
              </Button>
            )}
          </DropdownMenuItem>
        ))}
        {unreadCount === 0 && (
          <div className="text-center py-2 text-sm text-muted-foreground">
            No new notifications
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}