//frontend/solyo/app/ClientLayout.tsx
'use client';
import React, { useState, useEffect } from 'react';
import { UserButton, useUser } from '@clerk/nextjs';
import { usePathname } from 'next/navigation';
import dynamic from 'next/dynamic';
import { ThemeToggle } from '@/components/Functions/Header/Dark_Mode_Toggle';
import NotificationCenter from '@/components/Functions/Header/Notification_Center'
import AskSolyo from '@/components/Functions/Header/Ask_Solyo'
import Sidebar from "@/components/Block/Sidebar";
import AuthNav from "@/components/AuthNav";

const DynamicBackgroundSync = dynamic(
  () => import('@/components/background'),
  { ssr: false }
);

export default function ClientLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const [isHomePage, setIsHomePage] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { isSignedIn, isLoaded } = useUser();

  useEffect(() => {
    setIsHomePage(pathname === '/');
  }, [pathname]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <>
      <DynamicBackgroundSync />
      <div className="fixed top-4 right-4 z-[9999] flex items-center space-x-4">
        {isLoaded && isSignedIn ? (
          <>
            <ThemeToggle />
            <NotificationCenter />
            <AskSolyo />
            <UserButton afterSignOutUrl="/" />
          </>
        ) : !isHomePage && (
          <>
            <ThemeToggle />
            <AuthNav variant="ghost" />
          </>
        )}
      </div>
      {!isHomePage && isSignedIn && (
        <div
          className={`fixed top-0 left-0 h-full transition-all duration-300`}
          style={{ width: isSidebarOpen ? '200px' : '64px', zIndex: 1000 }}
        >
          <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
        </div>
      )}
      <div
        className={`flex flex-col flex-grow transition-all duration-300`}
        style={{
          marginLeft: !isHomePage && isSignedIn ? (isSidebarOpen ? '200px' : '64px') : '0',
          width: isHomePage ? '100%' : 'auto',
        }}
      >
        <main className={isHomePage ? 'w-full' : ''}>
          {children}
        </main>
      </div>
    </>
  );
}


