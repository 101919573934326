//frontend/solyo/components/Functions/Header/Ask_Solyo.tsx
'use client'

import { MessageSquare } from 'lucide-react'
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"

export default function AskSolyo() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" aria-label="Learn About Solyo">
          <MessageSquare className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56 p-0">
        <div className="px-2 py-1.5 bg-gray-800 text-white">
          <h3 className="font-semibold text-sm">Learn About Solyo</h3>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
        <a href="https://docs.solyo.ai" target="_blank" rel="noopener noreferrer" className="flex items-center px-2 py-1.5">
        Chat with Doc
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <a href="https://docs.solyo.ai" target="_blank" rel="noopener noreferrer" className="flex items-center px-2 py-1.5">
            Documentation
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}