'use client';

import { useEffect, useState } from 'react';
import { useUser, UserButton, SignInButton } from '@clerk/clerk-react';
import type { UserResource } from '@clerk/types';

interface UserDetails {
  firstName: string | null;
  lastName: string | null;
  imageUrl: string;
  primaryEmail: string | undefined;
}

interface MongoUserDetails {
  contract: string;
}

export default function UserItem({ isOpen }: { isOpen: boolean }) {
  const { user, isSignedIn } = useUser();
  const [userData, setUserData] = useState<UserDetails | null>(null);
  const [mongoUserData, setMongoUserData] = useState<MongoUserDetails | null>(null);

  useEffect(() => {
    if (isSignedIn && user) {
      const primaryEmail = user.emailAddresses.find(email => email.id === user.primaryEmailAddressId)?.emailAddress;
      const userDetails: UserDetails = {
        firstName: user.firstName,
        lastName: user.lastName,
        imageUrl: user.imageUrl,
        primaryEmail,
      };
      setUserData(userDetails);
    }
  }, [isSignedIn, user]);

  useEffect(() => {
    const fetchMongoUserData = async () => {
      if (user && user.id) {
        try {
          const response = await fetch(`/api/User_Details?clerkId=${user.id}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data: MongoUserDetails = await response.json();
          setMongoUserData(data);
        } catch (error) {
          console.error('Error fetching user data from MongoDB:', error);
        }
      }
    };

    fetchMongoUserData();
  }, [user]);

  if (!isSignedIn || !userData) {
    return (
      <div className={`flex items-center justify-center ${isOpen ? 'w-[180px]' : 'w-[40px]'}`}>
        <SignInButton mode="modal" />
      </div>
    );
  }

  return (
    <div className={`flex items-center justify-between gap-2 p-2 ${isOpen ? 'w-[180px] rounded-[8px]' : 'w-[40px]'}`}>
      <UserButton userProfileMode="modal" appearance={{ elements: { avatar: { width: '40px', height: '40px' } } }} />
      {isOpen && (
        <div className="grow">
          <p className="text-[12px] font-bold">{userData.firstName}</p>
          <p className="text-[12px] text-white">{mongoUserData?.contract || 'N/A'}</p>
        </div>
      )}
    </div>
  );
}




