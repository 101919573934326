//frontend/solyo/components/Block/Sidebar.tsx
import { useState, useEffect } from "react";
import { useUser } from "@clerk/nextjs";
import { 
  Activity, 
  Calendar,
  LayoutGrid, 
  GraduationCap, 
  UserRoundSearch,
  Mail,
  ClipboardCheck,
  Rss,
  MessageSquare, 
  Upload, 
  Menu, 
  User, 
  Link2,
} from "lucide-react";
import { Command, CommandList } from "../ui/command";
import UserItem from "../ui/UserItem";
import { useRouter, usePathname } from "next/navigation";

interface MenuItem {
  link: string;
  icon: JSX.Element;
  text: string;
}

interface MenuGroup {
  group: string;
  items: MenuItem[];
}

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

interface UserAppData {
  appName: string;
  status: 'active' | 'inactive' | 'pending' | 'cancelled';
}

// App configurations mapping
const appConfigs = {
  'PowerSchool_Grade': {
    link: '/School-Grades',
    icon: <GraduationCap className="transition-colors duration-200" />,
    text: 'School'
  },
  'Calendar': {
    link: '/Calendar',
    icon: <Calendar className="transition-colors duration-200" />,
    text: 'Calendar'
  },
  'TaskManager': {
    link: '/Tasks',
    icon: <ClipboardCheck className="transition-colors duration-200" />,
    text: 'Tasks'
  },
  'BudgetTracker': {
    link: '/Budget',
    icon: <Activity className="transition-colors duration-200" />,
    text: 'Budget'
  },
  'FitnessPal': {
    link: '/Fitness',
    icon: <Activity className="transition-colors duration-200" />,
    text: 'Fitness'
  },
  'ShopSmart': {
    link: '/Shopping',
    icon: <Activity className="transition-colors duration-200" />,
    text: 'Shopping'
  }
};

const usageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="transition-colors duration-200"
  >
    <path d="M12 16v5" />
    <path d="M16 14v7" />
    <path d="M20 10v11" />
    <path d="m22 3-8.646 8.646a.5.5 0 0 1-.708 0L9.354 8.354a.5.5 0 0 0-.707 0L2 15" />
    <path d="M4 18v3" />
    <path d="M8 14v7" />
  </svg>
);

const Sidebar = ({ isOpen, onToggle }: SidebarProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const { user } = useUser();
  const [selectedLink, setSelectedLink] = useState<string | null>(pathname);
  const [activeApps, setActiveApps] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch user's active apps from MongoDB
  useEffect(() => {
    const fetchUserApps = async () => {
      if (!user?.id) return;

      try {
        const response = await fetch(`/api/User_Details?clerkId=${user.id}`);
        if (!response.ok) throw new Error('Failed to fetch user data');
        
        const userData = await response.json();
        const activeAppNames = userData.apps
          ?.filter((app: UserAppData) => app.status === 'active')
          .map((app: UserAppData) => app.appName) || [];
        
        setActiveApps(activeAppNames);
      } catch (error) {
        console.error('Error fetching user apps:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserApps();
  }, [user?.id]);

  const handleItemClick = (link: string) => {
    setSelectedLink(link);
    router.push(link);
  };

  // Generate dynamic My Apps menu items
  const getMyAppsItems = (): MenuItem[] => {
    // Always include App Store
    const items: MenuItem[] = [
      { 
        link: "/AppStore", 
        icon: <LayoutGrid className="transition-colors duration-200" />, 
        text: "App Store" 
      }
    ];

    // Add active apps
    activeApps.forEach(appName => {
      const appConfig = appConfigs[appName as keyof typeof appConfigs];
      if (appConfig) {
        items.push({
          link: appConfig.link,
          icon: appConfig.icon,
          text: appConfig.text
        });
      }
    });

    return items;
  };

  // Define menu structure
  const menuList: MenuGroup[] = [
    {
      group: "Ingest",
      items: [
        { link: "/Integrations", icon: <Link2 className="transition-colors duration-200" />, text: "Integrations" },
        { link: "/Collector", icon: <Activity className="transition-colors duration-200" />, text: "Collectors" },
        { link: "/Upload", icon: <Upload className="transition-colors duration-200" />, text: "Upload" },
      ]
    },
    {
      group: "Explore",
      items: [
        { link: "/Chat2", icon: <MessageSquare className="transition-colors duration-200" />, text: "Chat" },
        { link: "/News", icon: <Rss className="transition-colors duration-200" />, text: "News" },
        { link: "/Contact", icon: <UserRoundSearch className="transition-colors duration-200" />, text: "Contact" },
        { link: "/Tasks", icon: <ClipboardCheck className="transition-colors duration-200" />, text: "Tasks" },

      ]
    },
    {
      group: "My Apps",
      items: getMyAppsItems() // Dynamic apps list
    },
    {
      group: "Profile",
      items: [
        { link: "/Account", icon: <User className="transition-colors duration-200" />, text: "Account" },
        { link: "/Usage", icon: usageIcon, text: "Usage" }
      ]
    }
  ];

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div 
      className={`flex flex-col h-full min-h-screen transition-all duration-300 ${isOpen ? 'w-[200px]' : 'w-[64px]'}`}
      style={{ 
        zIndex: 1000, 
        color: 'white', 
        fontFamily: 'ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Apple Color Emoji, Arial, Segoe UI Symbol',
        background: 'linear-gradient(to bottom, #003366, #00122A 50%, #003366)'
      }}
    >
      <div className="flex justify-between items-center mb-4" style={{ backgroundColor: 'transparent' }}>
        <div className={`text-3xl font-bold ${isOpen ? 'ml-4' : 'hidden'}`}>Solyo</div>
        <button
          onClick={onToggle}
          className={`sidebar-toggle-btn flex items-center justify-center ${isOpen ? '' : 'w-full h-12'}`}
        >
          {isOpen ? <Menu className="text-white" /> : <Menu className="rotate-180 text-white" />}
        </button>
      </div>

      <div className="grow flex flex-col gap-6" style={{ background: 'transparent' }}>
        <Command className="text-white" style={{ overflow: 'visible', background: 'transparent' }}>
          <CommandList style={{ overflow: 'visible', background: 'transparent' }}>
            {menuList.map((menu, key) => (
              <div key={key} className="mb-4">
                <span className={`block ${isOpen ? 'pl-4 mb-3 mt-4 font-bold' : 'hidden'}`} style={{ color: 'white' }}>
                  {menu.group}
                </span>
                <div className="flex flex-col mt-4">
                  {menu.items.map((option, optionKey) => (
                    <div
                      key={optionKey}
                      onClick={() => handleItemClick(option.link)}
                      className={`
                        cursor-pointer 
                        sidebar-item 
                        flex 
                        ${isOpen ? 'pl-4' : 'justify-center'} 
                        h-12 
                        items-center 
                        transition-colors 
                        duration-200
                        hover:bg-white/10
                        ${selectedLink === option.link ? 'bg-white/20' : ''}
                      `}
                    >
                      <div className="text-white">
                        {option.icon}
                      </div>
                      <span className={`ml-2 ${isOpen ? '' : 'hidden'} text-white`}>
                        {option.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </CommandList>
        </Command>
      </div>

      <div style={{ backgroundColor: 'transparent' }}>
        <UserItem isOpen={isOpen} />
      </div>
    </div>
  );
};

export default Sidebar;





/*
        { link: "/Integrations", icon: <Link2 className="text-white group-hover:text-[#0E2841]" />, text: "Integrations" },
 //       { link: "/Collectors", icon: <RefreshCcw className="text-white group-hover:text-[#0E2841]" />, text: "Collectors" },
        { link: "/Collector", icon: <Activity className="text-white group-hover:text-[#0E2841]" />, text: "Collectors" },

        { link: "/Upload", icon: <Upload className="text-white group-hover:text-[#0E2841]" />, text: "Upload" },
       // { link: "/Templates", icon: <FileText className="text-white group-hover:text-[#0E2841]" />, text: "Templates" },
        { link: "/Integrations", icon: <Link2 className="transition-colors duration-200" />, text: "Integrations" },
        { link: "/Collector", icon: <Activity className="transition-colors duration-200" />, text: "Collectors" },
        { link: "/Upload", icon: <Upload className="transition-colors duration-200" />, text: "Upload" },
      ]
    },
    {
      group: "Explore",
      items: [
 //       { link: "/Chat", icon: <MessageSquare className="text-white group-hover:text-[#0E2841]" />, text: "Chat" },
        { link: "/Chat2", icon: <MessageSquare className="text-white group-hover:text-[#0E2841]" />, text: "Chat" },
        { link: "/News", icon: <Rss className="text-white group-hover:text-[#0E2841]" />, text: "News" },
        { link: "/Contact", icon: <UserRoundSearch className="text-white group-hover:text-[#0E2841]" />, text: "Contact" },

  //      { link: "/Candidate", icon: <LayoutList className="text-white group-hover:text-[#0E2841]" />, text: "Candidates" }

      //  { link: "/Extract", icon: <LayoutDashboard className="text-white group-hover:text-[#0E2841]" />, text: "Extract" },

  //      { link: "/Email", icon: <MessageSquare className="text-white group-hover:text-[#0E2841]" />, text: "Email" }

        { link: "/Chat2", icon: <MessageSquare className="transition-colors duration-200" />, text: "Chat" },
        { link: "/News", icon: <Rss className="transition-colors duration-200" />, text: "News" },
        { link: "/Contact", icon: <UserRoundSearch className="transition-colors duration-200" />, text: "Contact" },
      ]
    },
    {
      group: "Automate",
      items: [
        { link: "/Tasks", icon: <ClipboardCheck className="text-white group-hover:text-[#0E2841]" />, text: "Tasks" },
   //      { link: "/Extract2", icon: <LayoutDashboard className="text-white group-hover:text-[#0E2841]" />, text: "Extract" },
   //      { link: "/Extract3", icon: <LayoutDashboard className="text-white group-hover:text-[#0E2841]" />, text: "Extract3" },
        { link: "/Tasks", icon: <ClipboardCheck className="transition-colors duration-200" />, text: "Tasks" },
      ]
    },
    {
      group: "Profile",
      items: [
        { link: "/Account", icon: <User className="text-white group-hover:text-[#0E2841]" />, text: "Account" },
        { link: "/Account", icon: <User className="transition-colors duration-200" />, text: "Account" },




*/












